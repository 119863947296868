import GenCodeCard from './GenCodeCard';
import {ref,reactive,toRefs, onMounted,defineComponent} from 'vue';
const GenCodeListHelper = {
    name: 'GenCodeList',
    setup(){
        let dataObj=reactive({
            pageListRef:null,
            operId: "",
            pageList: {
                queryParam: {
                    tbName: "",
                    modelName: ""
                },
                modelComp:GenCodeCard,
                modelMethod: "/genCode/pageData"
            }
        })
        //---------------数据表格行格式化
        const formatterBillFlag=(row, column, value, index)=>{
            if ('0' == value) {
                return "卡片";
            } else if ('1' == value) {
                return "单据";
            } else {
                return "";
            }
        }
        const filterBillFlag=(value, row)=>{
            return row.F_BILL_FLAG === value;
        }
        return{
            ...toRefs(dataObj),formatterBillFlag,filterBillFlag
        }
    },
    components: {
 
    }
};
export default GenCodeListHelper;